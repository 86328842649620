<template>
  <div class="livro-container">
    
    <!-- -->
      <section class="container-fluid" style="background:#F9F7EA;">

        <div class="row">
            
            <div class="col-sm-12 px-lg-5">
            <br>
                      <h3 class="txt-titulo-roxo bold" >Livro de receitas</h3>
                      <p class="txt-paragrafo-roxo">
O Livro de Receitas é seu. Você pode incluir e excluir as receitas tarimbadas quando quiser.<br> Para isso, você precisa apenas avaliar ou comentar, nos espaços reservados, ao final de cada receita que você tiver interesse em guardar no seu Livro.</p>
<br>
<h4 class="txt-titulo-roxo bold" >Escolha sua Receita</h4>
<p class="txt-paragrafo-roxo">
Para encontrar uma receita em seu Livro, basta selecionar as informações abaixo e clicar em "Aplicar Filtros". Ou ainda, se preferir, faça sua busca pelo nome receita.</p>
<br>
<Anuncio :id="3" padrao="/img/banner-propaganda5.png" class="d-none d-sm-block" :mobile="false" />
<Anuncio :id="3" padrao="/img/banner-propaganda5.png" class="d-sm-none" :mobile="true" />
<br>

<Busca :filtros.sync="filtrosBusca" @buscar="buscarResultados" :showIngredientes="true" urlNome="autocomplete/receitasLivro/"></Busca>

<!-- cards-->
<div class="row">
          <CardReceita v-for="(item,index) in receitas" :key="'receitaTeste'+index" @deletarReceita="openModalReceita" :receita="item" :showIconeDeletar="true" ></CardReceita>
</div>
<!-- -->

<div v-if="showLoading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

  <p v-if="semResultados" class="text-center bold">Sem Resultados...</p>
 <div class="d-flex justify-content-center">
 
        <button v-if="semResultados == false" @click="paginar" style="width:300px;" class="btn btn-light btn-block btn-search-ingredients bold">CARREGAR MAIS RECEITAS</button>
         </div>
        
        <br><br>


          </div>
                  
       </div>
                  
      </section>
<!-- -->


 <div class="modal" id="modal-compartilhe" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações importantes!</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-excluir-lista" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Exclusão de receita!</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Você realmente deseja deletar esse item da sua lista?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        <button @click="excluir" type="button" class="btn btn-secondary" data-dismiss="modal">Sim, Quero remover.</button>
        
      </div>
    </div>
  </div>
</div>






  </div>
</template>

<style>
.txt-titulo-roxo{color:#440D45;font-weight:bold;}

.btn-criar-receita {
    margin-top: 2%;
   
    background-color: #400041;
    border: none;
    color: #fff;
    max-width:300px !important;
}
</style>

<script>
import servidor from '@/servidor'
import Busca from '@/components/Busca'
import CardReceita from '@/components/CardReceita'
import Anuncio from '@/components/Anuncio'

export default {
  name: 'LivroReceitas',
  metaInfo: {
    title: 'Livro de receitas - Tarimba na cozinha'
  },
  components: {Busca,CardReceita,Anuncio},
  data(){
    return {
      semResultados:false,
      showLoading:false,
      receitas:[],
      filtrosBusca: new FormData(),
      texto:'',
      receitaAserDeletada:0
    }
  },
  computed:{
    getUsuario(){
      return this.$store.state.usuario.id;
    }
  },
  methods:{
    
    openModalReceita(id){
      this.receitaAserDeletada = id;
      window.jQuery('#modal-excluir-lista').modal('show');
    },
    abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-compartilhe').modal('show');
   },
    excluir(){
      if(this.receitaAserDeletada > 0){
        fetch(servidor+'buscaTarimba/deletarReceitaLivro/'+ this.receitaAserDeletada + "/" + this.getUsuario)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            let filtro = new FormData();
            filtro.append('paginacao',0);
            this.buscarResultados(filtro);
            this.abrirModal("Receita excluida da sua lista com sucesso!");
          });
      }
    },
    
    buscarResultados(filtros){
       this.semResultados = false;
       this.receitas = [];
       this.showLoading = true;
       filtros.append('paginacao',0);
       fetch(servidor+'buscaTarimba/receitasLivro/'+ this.getUsuario,{method:"POST", body: filtros})
          .then((response) => response.json())
          .then((data) => {
             if(data == null || data.length == 0){ this.semResultados = true;}
            this.receitas = data;
            this.showLoading = false;
            console.log(data)
          });
    },
    paginar(){
       this.semResultados = false;
       this.showLoading = true;
       this.filtrosBusca.append('paginacao',this.receitas.length);
       fetch(servidor+'buscaTarimba/receitasLivro/'+ this.getUsuario,{method:"POST", body: this.filtrosBusca})
          .then((response) => response.json())
          .then((data) => {
             if(data == null || data.length == 0){ this.semResultados = true;}
            data.forEach(element => {
                this.receitas.push(element);
            });
            this.showLoading = false;
           
          });

    }
  },
  mounted(){
    let filtro = new FormData();
    filtro.append('paginacao',0);
    this.buscarResultados(filtro);

    
  }
}
</script>
